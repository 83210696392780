export * from './fetch'

/**
 * An example use case:
 *
 * Execute a function after the user has finished inputting a search value.
 * After calling `run`, it will wait `frequency` time to execute the function.
 * If `run` is called again before `frequency` time passes, then `run` will be called
 * after `frequency` again (resetting the timer).
 *
 * We don't want to make an api call after each input from the user.
 * We want to wait some time to make sure we're not making too many calls.
 */
export class ExecuteWithFrequency<Parameter> {
  private timeout: any

  constructor(
    private func: (parameter: Parameter) => void,
    private frequency = 500
  ) {}

  public run(arg: Parameter) {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.func(arg)
    }, this.frequency)
  }

  public clear() {
    clearTimeout(this.timeout)
  }
}
