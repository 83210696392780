export const backendFetch = async <T>(
  path: string,
  init?: RequestInit
): Promise<{ status: number; data?: T }> => {
  return fetch(process.env.VUE_APP_BACKEND_HOST + path, {
    credentials: 'include',
    ...init,
  }).then(async (r) => {
    const isError = r.status >= 300
    try {
      const data = await r.json()
      const responseData = {
        status: r.status,
        data: isError ? null : data,
        errors: isError ? data : null,
      }
      return responseData
    } catch (error) {
      return {
        status: r.status,
        errors: isError ? ['An error occurred.'] : [],
      }
    }
  })
}
