<template>
  <span class="flex items-center justify-center">
    <span class="spinner"></span>
  </span>
</template>

<style scoped>
.spinner {
  @apply w-0 h-0 border-4 border-solid border-gray-800 p-1.5 rounded-full animate-spin;
  border-right-color: transparent;
}
</style>
