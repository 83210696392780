
import { computed, defineComponent, reactive, toRefs } from 'vue'

import { backendFetch, ExecuteWithFrequency } from '@/helpers'
import { PendingSubscription } from '@/types'

import Spinner from '@/components/atoms/Spinner'

type State = {
  username: string
  isLoading: boolean
  hasLoadedOnce: boolean
  pendingSubscription?: PendingSubscription
}

export default defineComponent({
  components: {
    Spinner,
  },
  setup() {
    const state = reactive<State>({
      username: '',
      isLoading: false,
      hasLoadedOnce: false,
      pendingSubscription: undefined,
    })

    const onInputUsername = (e: Event & { target: HTMLInputElement }) => {
      state.username = e.target.value

      if (!state.username) {
        checkForPendingSubscription.clear()
        return
      }

      state.isLoading = true
      checkForPendingSubscription.run(state.username)
    }

    const checkForPendingSubscription = new ExecuteWithFrequency(
      async (username: string) => {
        if (!username) {
          state.isLoading = false
          return
        }

        const { data } = await backendFetch<PendingSubscription>(
          `/api/subscriptions/pending/${username}`
        )
        state.hasLoadedOnce = true
        state.pendingSubscription = data
        state.isLoading = false
      }
    )

    /**
     * User subscription data.
     */
    const s = computed(() => {
      return state.pendingSubscription?.availableSubscriptions
    })

    return {
      s,
      onInputUsername,
      ...toRefs(state),
    }
  },
})
