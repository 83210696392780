import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home'

export const routeNames = {
  HOME: 'HOME',
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: routeNames.HOME,
    component: Home,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
